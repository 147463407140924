<template>
  <div>
    <SelectionMenu />
    <Menu />
    <div
      class="col-12 col-md-8 col-lg-8 offset-md-2 offset-lg-2 mt-5 person-info-form"
    >
      <h2 class="text-center mb-5">{{ $t("personalInfo") }}</h2>
      <ProfileForm />
    </div>
  </div>
</template>

<script>
import Menu from "../components/start/base/Menu.vue";
import SelectionMenu from "../components/start/base/SelectionMenu.vue";
import ProfileForm from "../components/profile/ProfileForm.vue";

export default {
  components: {
    Menu,
    SelectionMenu,
    ProfileForm,
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .person-info-form {
    margin-top: 6rem !important;
  }
}
</style>