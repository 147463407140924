<template>
  <div class="text-dark-blue form-style" v-if="!pageLoading">
    <div class="person-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <Alert :alert="alert" />
      <b-form class="ml-0 mt-5" @submit.prevent="updateUserInfo">
        <div class="row">
          <b-form-group class="col-6">
            <label for="name">{{ $t("name") }}</label>
            <b-form-input
              type="text"
              id="name"
              v-model="$v.form.name.$model"
              :state="!$v.form.name.$error"
              :disabled="true"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">{{
              $t("validateMessages.pleaseName")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-6">
            <label for="surname">{{ $t("surname") }}</label>
            <b-form-input
              type="text"
              id="surname"
              v-model="$v.form.surname.$model"
              :state="!$v.form.surname.$error"
              :disabled="true"
            />
            <b-form-invalid-feedback v-if="!$v.form.surname.required">{{
              $t("validateMessages.pleaseSurname")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group>
          <label for="date">{{ $t("birth") }}</label>
          <!-- <b-form-input
            type="date"
            id="date"
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            :disabled="true"
          /> -->
          <v-date-picker
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            :locale="$i18n.locale"
            is-required
            :popover="{ visibility: 'click' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-form-input
                :value="inputValue"
                v-on="inputEvents"
                readonly
                disabled
              />
            </template>
          </v-date-picker>
          <b-form-invalid-feedback v-if="!$v.form.birthDate.required">{{
            $t("validateMessages.pleaseBirthDate")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <p class="font-weight-bold mt-3">{{ $t("address") }}</p>
        <p class="font-size-14 mb-3">
          {{ $t("addressText") }}
          <span class="font-weight-bold">{{ $t("addressRegistration") }}</span>
          {{ $t("addressGermany") }}
        </p>
        <div class="row">
          <b-form-group class="col-8">
            <label for="street">{{ $t("street") }}</label>
            <b-form-input
              type="text"
              id="street"
              v-model="$v.form.address.street.$model"
              :state="!$v.form.address.street.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.address.street.required">{{
              $t("validateMessages.pleaseStreet")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-4">
            <label for="apartmentNo">{{ $t("number") }}</label>
            <b-form-input
              type="number"
              id="apartmentNo"
              v-model="$v.form.address.apartmentNo.$model"
              :state="!$v.form.address.apartmentNo.$error"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.address.apartmentNo.required"
              >{{
                $t("validateMessages.pleaseNumber")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
        </div>
        <div class="row">
          <b-form-group class="col-6">
            <label for="code">{{ $t("postCode") }}</label>
            <b-form-input
              type="text"
              id="code"
              v-model="$v.form.address.postalCode.$model"
              :state="!$v.form.address.postalCode.$error"
            />
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.required"
              >{{
                $t("validateMessages.pleasePostalCode")
              }}</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.minLength"
              >{{
                $t("validateMessages.minPostalCodeText")
              }}</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.form.address.postalCode.maxLength"
              >{{
                $t("validateMessages.maxPostalCodeText")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group class="col-6">
            <label for="city">{{ $t("city") }}</label>
            <b-form-input
              type="text"
              id="city"
              v-model="$v.form.address.city.$model"
              :state="!$v.form.address.city.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.address.city.required">{{
              $t("validateMessages.pleaseCity")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group class="iban-text">
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showIbanInfo = false"
          >
            <label for="iban"
              >{{ $t("iban") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => ibanInfo()"
            /></label>
          </div>
          <div v-if="showIbanInfo" class="document-message">
            {{ $t("ibanInfoText") }}
          </div>
          <b-form-input
            type="text"
            id="iban"
            v-model="$v.form.iban.$model"
            :state="!$v.form.iban.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.iban.required">{{
            $t("validateMessages.ibanRequired")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.iban.maxLength">{{
            $t("validateMessages.ibanMaxLength")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.iban.minLength">{{
            $t("validateMessages.ibanMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showTaxIdentifyNo = false"
          >
            <label for="idNo"
              >{{ $t("taxId") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => showTaxIdentifyNoInfo()"
            /></label>
            <p class="text-muted font-size-14">{{ $t("optional") }}</p>
          </div>
          <div v-if="showTaxIdentifyNo" class="document-message">
            {{ $t("taxNoText") }}
          </div>
          <b-form-input
            type="text"
            id="idNo"
            v-model="$v.form.taxIdentifyNo.$model"
            :state="!$v.form.taxIdentifyNo.$error"
            :disabled="isDisabledId"
          />
          <b-form-invalid-feedback v-if="!$v.form.taxIdentifyNo.minLength">{{
            $t("validateMessages.idMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex justify-content-center w-100">
          <button class="blue-button w-100 mt-3 mb-5">
            <span v-if="!loading">{{ $t("save") }}</span>
            <b-spinner variant="light" class="font-size-14" v-else></b-spinner>
          </button>
        </div>
      </b-form>
    </div>
  </div>
  <div id="preloader" v-else>
    <div id="status">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import StepTitle from "../register/base/StepTitle.vue";
import { mapGetters } from "vuex";
import {
  getUserInfo,
  putUserInfoComeFromForm,
} from "../../services/modules/User";
import Alert from "../base/alert/Alert.vue";
import { validationMixin } from "vuelidate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const { required, minLength, maxLength } = require("vuelidate/lib/validators");

export default {
  components: {
    StepTitle,
    Alert,
    FontAwesomeIcon,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        phoneNo: null,
        name: "",
        surname: "",
        birthDate: "",
        address: {
          street: "",
          postalCode: "",
          city: "",
          apartmentNo: "",
        },
        iban: "",
        taxIdentifyNo: "",
        taxYear: null,
        marriageStatus: null,
        isActive: true,
        isDelete: true,
      },
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      pageLoading: false,
      loading: false,
      isDisabledId: false,
      faInfoCircle,
      showIbanInfo: false,
      showTaxIdentifyNo: false,
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      birthDate: {
        required,
      },
      taxIdentifyNo: {
        minLength: minLength(11),
      },
      iban: {
        required,
        maxLength: maxLength(22),
        minLength: minLength(22),
      },
      address: {
        street: {
          required,
        },
        postalCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        city: {
          required,
        },
        apartmentNo: {
          required,
        },
      },
    },
  },
  methods: {
    ibanInfo() {
      this.showIbanInfo = true;
    },
    showTaxIdentifyNoInfo() {
      this.showTaxIdentifyNo = true;
    },
    async userInfo() {
      this.pageLoading = true;
      const response = await getUserInfo(this.activeUser.id);

      if (response.message === "OK") {
        this.form = {
          ...response.data,
          iban: response.data.iban || "",
          taxIdentifyNo: response.data.taxIdentifyNo || "",
        };
      }
      this.pageLoading = false;
      if (this.form.taxIdentifyNo == "" || this.form.taxIdentifyNo == null) {
        this.isDisabledId = false;
      } else {
        this.isDisabledId = true;
      }
    },
    async updateUserInfo() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        if (this.form.iban != "") {
          if (!this.ibanValidation(this.form.iban)) {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "ibanFormat";
          } else {
            this.loading = true;
            const response = await putUserInfoComeFromForm(this.activeUser.id, {
              "Address.Street": this.form.address.street,
              "Address.ApartmentNo": this.form.address.apartmentNo,
              "Address.PostalCode": this.form.address.postalCode,
              "Address.City": this.form.address.city,
              IBAN: this.form.iban,
              TaxIdentifyNo: this.form.taxIdentifyNo,
            });

            if (response.message === "OK") {
              this.alert.show = true;
              this.alert.variant = "success";
              this.alert.message = "validateMessages.updateProfile";
            } else {
              this.alert.show = true;
              this.alert.variant = "danger";
              this.alert.message = "validateMessages.notUpdateProfile";
            }

            this.loading = false;
          }
        } else {
          this.loading = true;
          const response = await putUserInfoComeFromForm(this.activeUser.id, {
            "Address.Street": this.form.address.street,
            "Address.ApartmentNo": this.form.address.apartmentNo,
            "Address.PostalCode": this.form.address.postalCode,
            "Address.City": this.form.address.city,
            IBAN: this.form.iban,
            TaxIdentifyNo: this.form.taxIdentifyNo,
          });

          if (response.message === "OK") {
            this.alert.show = true;
            this.alert.variant = "success";
            this.alert.message = "validateMessages.updateProfile";
          } else {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "validateMessages.notUpdateProfile";
          }

          this.loading = false;
        }
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 2000);
    },
    ibanValidation(val) {
      return /^([a-zA-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
        val
      );
    },
  },
  mounted() {
    this.userInfo();

    if (
      (this.form.taxIdentifyNo == "" && this.form.iban == "null") ||
      this.form.iban == "undefined" ||
      this.form.iban == ""
    ) {
      this.form.taxIdentifyNo = "";
      this.form.iban = "";
    }
  },
};
</script>

<style>
</style>